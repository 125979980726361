<script setup>
const props = defineProps({
  modelValue: {
    type: [String, Number],
    required: false,
    default: '',
  },

  placeholder: {
    type: String,
    required: false,
    default: '',
  },

  label: {
    type: String,
    required: false,
    default: '',
  },

  inputType: {
    type: String,
    required: false,
    default: '',
  },

  required: {
    type: Boolean,
    required: false,
    default: false,
  },

  error: {
    type: Boolean,
    required: false,
    default: false,
  },

  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },

  showAsterisk: {
    type: Boolean,
    required: false,
    default: true,
  },
});

defineEmits(['update:modelValue', 'blur', 'focus']);

const computedPlaceholder = computed(() => {
  const text = props.placeholder || props.label || '';

  if (!text) return '';

  // TODO: the * should appear smaller and higher
  if (props.required && props.showAsterisk) {
    return `${text}*`;
  }

  return text;
});

const computedLabel = computed(() => {
  if (!props.label) return '';

  if (props.required && props.showAsterisk) {
    return `${props.label}*`;
  }

  return props.label;
});
</script>

<template>
  <div
    class="relative flex h-[52px] flex-col justify-center rounded-lg border-b-4 border-l border-r border-t border-black px-3 py-2"
    :class="{
      'border-red-400': error,
      'pr-[35px]': $slots.right,
      'border-tnNeutral-600 bg-gray-200': props.disabled,
    }"
  >
    <span
      v-if="modelValue && computedLabel"
      class="mb-1 text-xs leading-3 text-gray-400"
      >{{ computedLabel }}</span
    >

    <UInput
      :value="modelValue"
      :placeholder="computedPlaceholder"
      :type="inputType"
      size="sm"
      variant="none"
      :padded="false"
      :disabled="props.disabled"
      :required="props.required"
      :ui="{
        base: 'border-none outline-none focus:border-none focus:ring-0 placeholder:m-0',
        // iOS will zoom-in on inputs with a font-size less than 16px
        size: {
          sm: 'text-base font-medium placeholder:text-base placeholder:font-normal',
        },
      }"
      @input="$emit('update:modelValue', $event.target.value)"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
    >
      <template v-for="(_, name) in $slots" #[name]="scope">
        <slot :name v-bind="scope" />
      </template>
    </UInput>
    <div v-if="$slots.right" class="absolute right-[5px]">
      <slot name="right" />
    </div>
  </div>
</template>
